<template>
  <a-layout id="default-layout">
    <a-layout-sider
      :trigger="null"
      v-model="collapsed"
      theme="dark"
      collapsible
    >
      <div v-text="collapsed ? 'IFL' : 'Indie Film Lab'" class="logo" />
      <main-navigation></main-navigation>
    </a-layout-sider>
    <a-layout>
      <a-layout-header
        style="display: flex; align-items: center; background: #fff; padding: 0;"
      >
        <a-icon
          :type="collapsed ? 'menu-unfold' : 'menu-fold'"
          @click="collapsed = !collapsed"
          class="trigger"
        />
        <a-switch v-if="false" v-model="enableCamera">
          <a-icon slot="checkedChildren" type="qrcode" />
          <a-icon slot="unCheckedChildren" type="qrcode" />
        </a-switch>
        <smart-search style="width: 300px; margin-left: 1rem;" />
        <div style="flex: 1;" />
        <a-icon
          v-if="$auth.loggedIn"
          @click="showMyJobs = true"
          type="pushpin"
          class="trigger"
        />
      </a-layout-header>
      <a-layout-content
        :style="{
          margin: '24px',
          minHeight: '280px'
        }"
      >
        <nuxt />
      </a-layout-content>
      <a-layout-footer style="text-align: right">
        <build-version />
      </a-layout-footer>
    </a-layout>
    <code-reader v-if="enableCamera" />
    <my-jobs-drawer :visible="showMyJobs" @close="showMyJobs = false" />
  </a-layout>
</template>

<script>
import CodeReader from '~/components/CodeReader.vue'
import SmartSearch from '~/components/SmartSearch.vue'
import BuildVersion from '~/components/BuildVersion.vue'
import MyJobsDrawer from '~/components/MyJobsDrawer.vue'
import MainNavigation from '~/components/MainNavigation.vue'

export default {
  components: {
    CodeReader,
    SmartSearch,
    BuildVersion,
    MyJobsDrawer,
    MainNavigation
  },
  head() {
    return {
      title: 'IFL Crew'
    }
  },
  data() {
    return {
      collapsed: true,
      enableCamera: false,
      showMyJobs: false
    }
  },
  watch: {
    $route() {
      this.showMyJobs = false
    }
  },
  mounted() {
    this.$notification.config({
      placement: 'topLeft',
      top: '72px'
    })
    setTimeout(() => {
      this.$store.commit('comments/CLEAR_EXPIRED_READ_TIMES')
      setInterval(() => {
        this.$store.commit('comments/CLEAR_EXPIRED_READ_TIMES')
      }, 10 * 60 * 1000)
    }, 5000)
  }
}
</script>

<style>
#default-layout {
  min-height: 100vh;
}

#default-layout .trigger {
  font-size: 18px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#default-layout .trigger:hover {
  color: #1890ff;
}

#default-layout .logo {
  height: 32px;
  /*background: rgba(255, 255, 255, 0.2);*/
  color: #fff;
  margin: 16px;
}
</style>
