<template>
  <span>
    <a-tooltip :title="'Build #' + currentBuild" placement="left">
      <a-icon type="build" />
    </a-tooltip>
    <a-button
      v-if="updateAvailable"
      @click="loadUpdate"
      type="primary"
      size="small"
      icon="reload"
    >
      Update Available
    </a-button>
  </span>
</template>

<script>
export default {
  props: {
    timeout: {
      type: Number,
      default: 6 * 60 * 60 * 1000
    }
  },
  data() {
    return {
      interval: null,
      latestBuild: null
    }
  },
  computed: {
    currentBuild() {
      return process.env.buildNumber
    },
    updateAvailable() {
      return this.latestBuild !== null && this.latestBuild !== this.currentBuild
    }
  },
  mounted() {
    this.startChecking()
  },
  methods: {
    startChecking() {
      this.interval = setInterval(this.checkForNewBuild, this.timeout)
    },
    stopChecking() {
      clearInterval(this.interval)
    },
    loadUpdate() {
      location.reload()
    },
    async checkForNewBuild() {
      this.latestBuild = await this.fetchLatestBuildNumber()
      if (this.updateAvailable) {
        this.stopChecking()
        this.$confirm({
          title: 'Update Available',
          icon: 'reload',
          content:
            'Please reload to start using the latest version of the site.',
          okText: 'Reload now',
          cancelText: 'Remind me later',
          onOk: () => {
            this.loadUpdate()
          },
          onCancel: () => {
            setTimeout(this.startChecking, this.timeout)
          }
        })
      }
    },
    async fetchLatestBuildNumber() {
      const response = await this.$axios({
        url: 'version.txt',
        baseURL: '/',
        progress: false
      })
      return response.data.trim()
    }
  }
}
</script>
