<template>
  <div class="OrderAlertIcons">
    <a-popover
      v-for="(alert, index) in alerts"
      :key="index"
      :title="alert.message"
      :content="alert.description"
    >
      <a-icon :type="alert.icon" :class="'text-' + alert.type" />
    </a-popover>
  </div>
</template>

<script>
import Order from '~/models/Order'

export default {
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  computed: {
    alerts() {
      return new Order(this.order).alerts
    }
  }
}
</script>

<style scoped>
.OrderAlertIcons {
  display: inline;
}
.text-error {
  color: #f5222d;
}
.text-warning {
  color: #faad14;
}
</style>
