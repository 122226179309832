export const state = () => ({
  readTimes: []
})

export const mutations = {
  MARK_READ(state, id) {
    const index = state.readTimes.findIndex((time) => time.id === id)
    if (index === -1) {
      state.readTimes.push({
        id,
        time: Date.now()
      })
    } else {
      state.readTimes[index].time = Date.now()
    }
  },
  CLEAR_EXPIRED_READ_TIMES(state) {
    const cutoff = Date.now() - 7 * 24 * 3600 * 1000
    let index = state.readTimes.length
    while (index--) {
      if (state.readTimes[index].time < cutoff) {
        state.readTimes.splice(index, 1)
      }
    }
  }
}

export const actions = {
  markRead({ state, commit }, id) {
    commit('MARK_READ', id)
  }
}

export const getters = {
  readIds: (state) => {
    return state.readTimes.map((time) => {
      return time.id
    })
  }
}
