import Vue from 'vue'
const uuidv4 = require('uuid/v4')

export const state = () => ({
  items: [],
  rush: false,
  returnNegatives: 0,
  adjustment: 0,
  removed: []
})

export const mutations = {
  reset(state) {
    state.items = []
    state.rush = false
    state.returnNegatives = 0
    state.adjustment = 0
    state.removed = []
  },
  add(state, { product, options, notes, price, quantity }) {
    state.items.push({
      id: uuidv4(),
      product,
      options,
      notes,
      price,
      quantity
    })
  },
  update(state, item) {
    const index = state.items.indexOf(
      state.items.find((existingItem) => {
        return existingItem.id === item.id
      })
    )
    state.items[index] = item
  },
  remove(state, id) {
    const removed = state.items.splice(
      state.items.indexOf(
        state.items.find((item) => {
          return item.id === id
        })
      ),
      1
    )
    state.removed.push(removed[0])
    Vue.notify({
      title: 'Removed Item',
      text: 'Item has been removed from your cart.'
    })
  },
  removeAll(state) {
    state.removed = state.removed.concat(state.items)
    state.items = []
    Vue.notify({
      title: 'Cart Cleared',
      text: 'All items have been removed from your cart.'
    })
  },
  restore(state, id) {
    const restored = state.removed.splice(
      state.removed.indexOf(
        state.removed.find((item) => {
          return item.id === id
        })
      ),
      1
    )
    state.items.push(restored[0])
    Vue.notify({
      title: 'Item Restored',
      text: "We've restored the item to your cart."
    })
  },
  updateQuantity(state, { id, quantity }) {
    state.items.find((item) => {
      return item.id === id
    }).quantity = quantity
  },
  setRush(state, isRush) {
    state.rush = isRush
  },
  setReturn(state, isReturn) {
    state.returnNegatives = isReturn
  },
  setDiscount(state, amount) {
    state.adjustment = -amount
  }
}

export const getters = {
  groupedItems: (state) => {
    return state.items.reduce((r, a) => {
      r[a.product] = [...(r[a.product] || []), a]
      return r
    }, {})
  },
  rollCount: (state) => {
    let count = 0
    state.items.forEach((item) => {
      count += item.quantity
    })
    return count
  },
  itemsTotal: (state) => {
    let total = 0
    state.items.forEach((item) => {
      total += item.price * item.quantity
    })
    return total
  },
  rushFee: (state, getters) => {
    if (state.rush) {
      return getters.itemsTotal * 0.5
    }
    return 0
  },
  returnFee: (state, getters) => {
    let fee = 0
    if (state.returnNegatives === 0) {
      fee += 900
      if (getters.rollCount > 10) {
        fee += 300
      }
    }
    return fee
  },
  priceComponents: (state, getters) => {
    const components = [{ name: 'Items', amount: getters.itemsTotal }]
    if (getters.rushFee !== 0) {
      components.push({ name: 'Rush', amount: getters.rushFee })
    }
    if (getters.returnFee !== 0) {
      components.push({ name: 'Sleeve & Return', amount: getters.returnFee })
    }
    if (state.adjustment !== 0) {
      components.push({ name: 'Discount', amount: state.adjustment })
    }
    return components
  },
  totalPrice: (state, getters) => {
    let total = 0
    getters.priceComponents.forEach((component) => {
      total += component.amount
    })
    return total
  },
  isEmpty: (state, getters) => {
    return getters.rollCount === 0
  }
}
