import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _20e6424b = () => interopDefault(import('../pages/gift-cards/index.vue' /* webpackChunkName: "pages/gift-cards/index" */))
const _9c357cca = () => interopDefault(import('../pages/jobs/index.vue' /* webpackChunkName: "pages/jobs/index" */))
const _125d1cd2 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _7a0d5eea = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _588b8cbf = () => interopDefault(import('../pages/tasks/index.vue' /* webpackChunkName: "pages/tasks/index" */))
const _458bc309 = () => interopDefault(import('../pages/tools.vue' /* webpackChunkName: "pages/tools" */))
const _829f628e = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _087ab4a9 = () => interopDefault(import('../pages/reports/balances.vue' /* webpackChunkName: "pages/reports/balances" */))
const _00792a32 = () => interopDefault(import('../pages/reports/rolls-by-station.vue' /* webpackChunkName: "pages/reports/rolls-by-station" */))
const _4334541c = () => interopDefault(import('../pages/reports/turnaround.vue' /* webpackChunkName: "pages/reports/turnaround" */))
const _0f4bd85c = () => interopDefault(import('../pages/orders/_id.vue' /* webpackChunkName: "pages/orders/_id" */))
const _3212157c = () => interopDefault(import('../pages/stations/_slug.vue' /* webpackChunkName: "pages/stations/_slug" */))
const _6576a93e = () => interopDefault(import('../pages/users/_id.vue' /* webpackChunkName: "pages/users/_id" */))
const _2d29ce80 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/gift-cards",
    component: _20e6424b,
    name: "gift-cards"
  }, {
    path: "/jobs",
    component: _9c357cca,
    name: "jobs"
  }, {
    path: "/login",
    component: _125d1cd2,
    name: "login"
  }, {
    path: "/orders",
    component: _7a0d5eea,
    name: "orders"
  }, {
    path: "/tasks",
    component: _588b8cbf,
    name: "tasks"
  }, {
    path: "/tools",
    component: _458bc309,
    name: "tools"
  }, {
    path: "/users",
    component: _829f628e,
    name: "users"
  }, {
    path: "/reports/balances",
    component: _087ab4a9,
    name: "reports-balances"
  }, {
    path: "/reports/rolls-by-station",
    component: _00792a32,
    name: "reports-rolls-by-station"
  }, {
    path: "/reports/turnaround",
    component: _4334541c,
    name: "reports-turnaround"
  }, {
    path: "/orders/:id",
    component: _0f4bd85c,
    name: "orders-id"
  }, {
    path: "/stations/:slug?",
    component: _3212157c,
    name: "stations-slug"
  }, {
    path: "/users/:id",
    component: _6576a93e,
    name: "users-id"
  }, {
    path: "/",
    component: _2d29ce80,
    name: "index"
  }],

  parseQuery: function(query) {
      return require('qs').parse(query)
    },
  stringifyQuery: function(query) {
      const result = require('qs').stringify(query)
      return result ? '?' + result : ''
    },
  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
