<template>
  <a-result
    :status="error.statusCode"
    :title="error.statusCode"
    :sub-title="error.message"
  >
    <template v-slot:extra>
      <nuxt-link to="/">Home</nuxt-link>
    </template>
  </a-result>
</template>

<script>
export default {
  props: {
    error: {
      type: Object,
      default() {
        return {}
      }
    }
  }
}
</script>
