<template>
  <a-drawer
    :closable="true"
    :visible="visible"
    :width="600"
    @close="$emit('close')"
    title="My Tasks"
    placement="right"
  >
    <div style="display: flex; justify-content: space-between;">
      <task-status-filter
        :filters="{ status }"
        @filtered="changedStatus"
        :dropdown="true"
        style="margin-bottom: 1rem;"
      />
      <a-button @click="fetchJobs" icon="reload" shape="circle" type="dashed" />
    </div>
    <a-pagination
      :current="page"
      :total="total"
      :page-size="pageSize"
      :disabled="loading"
      @change="loadPage"
      size="small"
      style="margin-bottom: 1rem;"
    />
    <tasks-table
      :tasks="jobs"
      :loading="loading"
      :hidden-columns="['user', 'updated_at', 'station', 'bundles', 'status']"
      :appended-columns="['station-status']"
      style="margin-bottom: 1rem;"
    />
    <a-pagination
      :current="page"
      :total="total"
      :page-size="pageSize"
      :disabled="loading"
      @change="loadPage"
      size="small"
    />
  </a-drawer>
</template>

<script>
import Task from '~/models/Task'
import TasksTable from '~/components/TasksTable.vue'
import TaskStatusFilter from '~/components/TaskStatusFilter.vue'
const pageSize = 10

function fetchJobsForUser(user, page, status) {
  const queryBuilder = Task.include('job.order.user')
    .page(page)
    .limit(pageSize)
    .orderBy('due_date')
    .where('user_id', user.id)
  if (status) {
    queryBuilder.where('status', status)
  } else {
    queryBuilder.where('completed', false)
  }
  return queryBuilder.get()
}

export default {
  components: {
    TasksTable,
    TaskStatusFilter
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      status: null,
      page: 1,
      pageSize,
      total: 0,
      jobs: []
    }
  },
  watch: {
    visible(newVal) {
      if (newVal === true) {
        this.page = 1
        this.fetchJobs()
      }
    }
  },
  methods: {
    changedStatus(filters) {
      this.status = filters.status
      this.fetchJobs()
    },
    async fetchJobs() {
      this.loading = true
      const { data, meta } = await fetchJobsForUser(
        this.$auth.user,
        this.page,
        this.status
      )
      this.jobs = data
      this.page = meta.current_page
      this.pageSize = meta.per_page
      this.total = meta.total
      this.loading = false
    },
    loadPage(page) {
      this.page = page
      this.fetchJobs()
    }
  }
}
</script>
