export const state = () => ({
  all: [],
  loaded: false
})

export const mutations = {
  setAll(state, employees) {
    state.all = employees
    state.loaded = true
  }
}

export const actions = {
  async loadAll({ commit, state }) {
    if (state.loaded) return
    const { data } = await this.$axios.get(`/api/v1/users`, {
      params: {
        'filter[role]': 'employee',
        'page[size]': 30,
        'page[number]': 1,
        sort: 'name'
      }
    })
    commit('setAll', data.data)
  }
}

export const getters = {
  all: (state) => {
    return state.all
  }
}
