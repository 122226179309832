export const state = () => ({
  all: [
    { name: 'C41 Process', id: 0, icon: 'experiment', slug: 'c41' },
    { name: 'B&W Process', id: 1, icon: 'experiment', slug: 'bw' },
    { name: 'E-6 Process', id: 2, icon: 'experiment', slug: 'e6' },
    { name: 'Frontier Scan', id: 3, icon: 'scan', slug: 'frontier' },
    { name: 'Noritsu Scan', id: 4, icon: 'scan', slug: 'noritsu' },
    { name: 'Flatbed Scan', id: 8, icon: 'scan', slug: 'flatbed' },
    { name: 'Dust', id: 5, icon: 'monitor', slug: 'dust' },
    { name: 'Correct', id: 6, icon: 'bg-colors', slug: 'correct' },
    { name: 'Upload', id: 7, icon: 'upload', slug: 'upload' },
    {
      name: 'Contact Sheet',
      id: 9,
      icon: 'border-outer',
      slug: 'contact-sheet'
    }
  ]
})

export const getters = {
  all: (state) => {
    return state.all
  },
  getStationBySlug: (state) => (slug) => {
    return state.all.find((station) => station.slug === slug)
  },
  getStationById: (state) => (id) => {
    return state.all.find((station) => station.id === id)
  }
}
