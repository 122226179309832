<template>
  <a-button @click="assignTask" :size="size">
    <slot>Claim</slot>
  </a-button>
</template>

<script>
export default {
  props: {
    task: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      default: 'default'
    }
  },
  methods: {
    async assignTask() {
      const { data } = await this.$axios.post(
        `/api/v1/tasks/${this.task.id}/assign`,
        {
          user_id: this.$auth.user.id
        }
      )
      this.$message.success(`Claimed ${this.task.station.name}`)
      this.$emit('assigned', data.data)
    }
  }
}
</script>
