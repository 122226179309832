<template>
  <a-dropdown :trigger="['click']" @visibleChange="dropdownOpened">
    <slot />
    <a-menu slot="overlay">
      <a-menu-item v-if="employees.length === 0" disabled>
        <a-avatar icon="user" size="small" /> Loading...
      </a-menu-item>
      <a-menu-item
        v-for="employee in employees"
        :key="employee.id"
        @click="assignTask(employee)"
      >
        <slot v-bind:employee="employee" name="menu-item">
          <user-avatar
            :user="employee"
            size="small"
            style="margin-right: 0.25rem;"
          />
          <span v-text="employee.name" />
        </slot>
      </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import UserAvatar from '~/components/UserAvatar.vue'

export default {
  components: {
    UserAvatar
  },
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('employees', {
      employees: 'all'
    })
  },
  methods: {
    ...mapActions('employees', {
      loadEmployees: 'loadAll'
    }),
    dropdownOpened() {
      this.loadEmployees()
    },
    async assignTask(employee) {
      const { data } = await this.$axios.post(
        `/api/v1/tasks/${this.task.id}/assign`,
        {
          user_id: employee.id
        }
      )
      this.$message.success(
        `Assigned ${this.task.station.name} to ${employee.name}`
      )
      this.$emit('assigned', data.data)
    }
  }
}
</script>
