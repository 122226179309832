<template>
  <a-input-search
    ref="searchField"
    v-model="searchTerm"
    @search="search"
    v-shortkey.once="['/']"
    @shortkey.native="shortcutListener"
    placeholder="Order, twin check, or customer"
  />
</template>

<script>
export default {
  data() {
    return {
      searchTerm: ''
    }
  },
  methods: {
    shortcutListener() {
      return this.$refs.searchField.focus()
    },
    search(input) {
      this.searchTerm = ''
      if (isNaN(input)) {
        this.$router.push({
          name: 'users',
          query: { filter: { name: input } }
        })
      } else if (input.length > 4) {
        this.$router.push({ name: 'orders-id', params: { id: input } })
      } else {
        this.$router.push({
          name: 'orders',
          query: { filter: { twin_check: input } }
        })
      }
    }
  }
}
</script>
