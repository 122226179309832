<template>
  <span class="UserTags">
    <a-tag v-for="tag in tags" :key="tag.id" v-text="tag.name" color="pink" />
  </span>
</template>

<script>
export default {
  props: {
    tags: {
      type: Array,
      required: true
    }
  }
}
</script>
