<template>
  <a-menu
    v-model="current"
    @click="menuItemClicked"
    theme="dark"
    mode="vertical"
  >
    <a-menu-item key="/"><a-icon type="home" /> <span>Home</span></a-menu-item>
    <a-menu-item key="/orders">
      <a-icon type="shop" /> <span>Orders</span>
    </a-menu-item>
    <a-sub-menu>
      <span slot="title" class="submenu-title-wrapper">
        <a-icon type="container" /> <span>Jobs</span>
      </span>
      <a-menu-item key="/jobs">
        <a-icon type="container" /> <span>All Jobs</span>
      </a-menu-item>
      <a-menu-item
        key="/jobs?filter[contains_option][correction]=0&filter[missing_option][correction]=1"
      >
        <a-icon type="container" /> <span>Standards</span>
      </a-menu-item>
      <a-menu-item :key="`/jobs?filter[claimant]=${$auth.user.id}`">
        <a-icon type="pushpin" /> <span>My Jobs</span>
      </a-menu-item>
    </a-sub-menu>
    <a-sub-menu>
      <span slot="title" class="submenu-title-wrapper">
        <a-icon type="deployment-unit" /> <span>Stations</span>
      </span>
      <a-menu-item
        v-for="station in stations"
        :key="'/stations/' + station.slug"
      >
        <station-icon :station="station" /> <span v-text="station.name" />
      </a-menu-item>
    </a-sub-menu>
    <a-sub-menu>
      <span slot="title" class="submenu-title-wrapper">
        <a-icon type="project" /> <span>Tasks</span>
      </span>
      <a-menu-item key="/tasks">
        <a-icon type="project" /> <span>All Tasks</span>
      </a-menu-item>
      <a-menu-item
        v-if="$auth.loggedIn"
        :key="'/tasks?filter[user_id]=' + $auth.user.id"
      >
        <a-icon type="pushpin" /> <span>My Tasks</span>
      </a-menu-item>
    </a-sub-menu>
    <a-menu-item key="/users">
      <a-icon type="team" /> <span>Customers</span>
    </a-menu-item>
    <a-sub-menu v-if="isManager">
      <span slot="title" class="submenu-title-wrapper">
        <a-icon type="bar-chart" /> <span>Reports</span>
      </span>
      <a-menu-item key="/reports/balances">
        <a-icon type="bar-chart" /> <span>Balances</span>
      </a-menu-item>
      <a-menu-item key="/reports/rolls-by-station">
        <a-icon type="bar-chart" /> <span>Rolls by station</span>
      </a-menu-item>
      <a-menu-item key="/reports/turnaround">
        <a-icon type="bar-chart" /> <span>Turnaround</span>
      </a-menu-item>
    </a-sub-menu>
    <a-menu-item key="/gift-cards" v-if="isManager">
      <a-icon type="gift" /> <span>Gift Cards</span>
    </a-menu-item>
    <a-menu-item key="/tools">
      <a-icon type="tool" /> <span>Tools</span>
    </a-menu-item>
    <a-sub-menu>
      <span slot="title" class="submenu-title-wrapper">
        <a-icon type="user" /> <span v-text="$auth.user.name" />
      </span>
      <a-menu-item key="logout">
        <a-icon type="logout" /> <span>Logout</span>
      </a-menu-item>
    </a-sub-menu>
  </a-menu>
</template>

<script>
import { mapGetters } from 'vuex'
import StationIcon from '~/components/StationIcon.vue'

export default {
  components: {
    StationIcon
  },
  data() {
    return {
      current: null
    }
  },
  computed: {
    isManager() {
      if (!this.$auth.loggedIn) return false
      return (
        this.$auth.user.roles.filter((role) => {
          return role.name === 'manager'
        }).length > 0
      )
    },
    ...mapGetters('stations', {
      stations: 'all'
    })
  },
  methods: {
    menuItemClicked({ key }) {
      if (key === 'logout') {
        return this.$auth.logout()
      }
      this.$router.push(key)
    }
  }
}
</script>
