export const state = () => ({
  all: []
})

export const mutations = {
  setAll(state, products) {
    state.all = products
  }
}

export const actions = {
  async loadAll({ commit }) {
    const response = await this.$axios.get('api/v1/products')
    commit('setAll', response.data.data)
  }
}

export const getters = {
  all: (state) => {
    return state.all
  }
}
