<template>
  <div>
    <a-button-group v-if="!dropdown">
      <a-button
        v-for="statusOption in statusOptions"
        :key="statusOption.value"
        :icon="statusOption.icon"
        :type="
          selectedStatus && statusOption.value === selectedStatus.value
            ? 'primary'
            : ''
        "
        @click="toggleStatus(statusOption)"
      >
        <span v-text="statusOption.name" />
      </a-button>
    </a-button-group>
    <a-dropdown v-else>
      <a-menu slot="overlay">
        <a-menu-item @click="clearStatus">
          <span>Any Status</span>
        </a-menu-item>
        <a-menu-item
          v-for="statusOption in statusOptions"
          :key="statusOption.value"
          :type="
            selectedStatus && statusOption.value === selectedStatus.value
              ? 'primary'
              : ''
          "
          @click="toggleStatus(statusOption)"
        >
          <a-icon :type="statusOption.icon" />
          <span v-text="statusOption.name" />
        </a-menu-item>
      </a-menu>
      <a-button>
        <a-icon v-if="selectedStatus" :type="selectedStatus.icon" />
        <span v-text="selectedStatus ? selectedStatus.name : 'Any Status'" />
        <a-icon type="down" />
      </a-button>
    </a-dropdown>
  </div>
</template>

<script>
const statusOptions = [
  { name: 'Unavailable', value: 'unavailable', icon: 'clock-circle' },
  { name: 'Available', value: 'available', icon: 'coffee' },
  { name: 'Started', value: 'started', icon: 'play-circle' },
  { name: 'Completed', value: 'completed', icon: 'check' }
]

export default {
  props: {
    filters: {
      type: Object,
      required: true
    },
    dropdown: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      statusOptions
    }
  },
  computed: {
    selectedStatus() {
      return statusOptions.find(
        (status) => status.value === this.filters.status
      )
    }
  },
  methods: {
    addFilter(filter) {
      this.addFilters([filter])
    },
    addFilters(filters) {
      const newFilters = Object.assign({}, this.filters)
      filters.forEach((filter) => {
        if (filter.value === null || filter.value === '') {
          delete newFilters[filter.key]
        } else {
          newFilters[filter.key] = filter.value
        }
      })
      this.setFilters(newFilters)
    },
    setFilters(filters) {
      this.$emit('filtered', filters)
    },
    clearStatus() {
      this.addFilter({ key: 'status', value: null })
    },
    toggleStatus(status) {
      if (this.selectedStatus && status.value === this.selectedStatus.value) {
        return this.clearStatus()
      }
      this.addFilter({ key: 'status', value: status.value })
    }
  }
}
</script>
