import moment from 'moment'
import Model from './Model'

export default class Order extends Model {
  resource() {
    return 'orders'
  }

  get completed() {
    return !!this.completed_at
  }

  get daysUntilDue() {
    if (this.due_date === null) return null
    return moment(this.due_date).diff(moment().startOf('day'), 'days')
  }

  get alerts() {
    const alerts = []
    if (this.rush)
      alerts.push({
        message: 'Rush',
        description: 'This is a rush order.',
        icon: 'thunderbolt',
        type: 'info'
      })
    if (!this.paid_at)
      alerts.push({
        message: 'Unpaid',
        description: 'This order has not been paid.',
        icon: 'dollar',
        type: 'error'
      })
    if (this.cancelled_at) {
      alerts.push({
        message: 'Cancelled',
        description: 'This order was cancelled.',
        icon: 'stop',
        type: 'error'
      })
      return alerts
    }
    if (this.daysUntilDue < 0 && !this.completed)
      alerts.push({
        message: 'Past Due',
        description:
          this.daysUntilDue === -1
            ? 'This order was due yesterday.'
            : 'This order was due ' + -this.daysUntilDue + ' days ago.',
        icon: 'hourglass',
        type: 'error'
      })
    if (this.daysUntilDue === 0 && !this.completed)
      alerts.push({
        message: 'Due Today',
        description: 'This order is due today.',
        type: 'warning',
        icon: 'hourglass'
      })
    if (this.daysUntilDue === 1 && !this.completed)
      alerts.push({
        message: 'Due Tomorrow',
        description: 'This order is due tomorrow.',
        type: 'info',
        icon: 'hourglass'
      })
    else if (
      !this.completed &&
      this.daysUntilDue > 1 &&
      this.daysUntilDue <= 3 &&
      moment(this.due_date).isoWeekday() === 1
    )
      alerts.push({
        message: 'Due Monday',
        description: 'This order is due Monday.',
        type: 'info',
        icon: 'hourglass'
      })
    return alerts
  }
}
