<template>
  <a-avatar
    :src="avatarUrl"
    :load-error="loadError"
    :size="size"
    :style="style"
  >
    <span v-text="user.name.match(/\b(\w)/g).join('')" />
  </a-avatar>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      default: 'default'
    }
  },
  data() {
    return {
      noAvatar: false
    }
  },
  computed: {
    avatarUrl() {
      if (this.noAvatar) {
        return null
      }
      return this.user.avatar
    },
    style() {
      let hash = 0
      for (let i = 0; i < this.user.email.length; i++) {
        hash = this.user.email.charCodeAt(i) + ((hash << 5) - hash)
      }
      let color = '#'
      for (let i = 0; i < 3; i++) {
        // eslint-disable-next-line
        const value = (hash >> (i * 8)) & 0xFF
        color += ('00' + value.toString(16)).substr(-2)
      }
      return {
        backgroundColor: color
      }
    }
  },
  methods: {
    loadError() {
      this.noAvatar = true
      return false
    }
  }
}
</script>
