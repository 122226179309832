<template>
  <div class="BundlesQuantity">
    <span
      v-for="sum in sums"
      :key="sum.singular"
      v-text="
        sum.quantity + ' ' + (sum.quantity === 1 ? sum.singular : sum.plural)
      "
    />
  </div>
</template>

<script>
export default {
  props: {
    bundles: {
      type: Array,
      required: true
    }
  },
  computed: {
    sums() {
      const sums = []
      this.bundles.forEach((bundle) => {
        const sumIndex = sums.findIndex((sum) => {
          return sum.singular === bundle.product.units[0]
        })
        if (sumIndex === -1) {
          sums.push({
            singular: bundle.product.units[0],
            plural: bundle.product.units[1],
            quantity: bundle.quantity
          })
        } else {
          sums[sumIndex].quantity += bundle.quantity
        }
      })

      return sums
    }
  }
}
</script>

<style type="text/css" scoped>
div {
  display: flex;
  flex-direction: column;
}
</style>
