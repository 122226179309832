<template>
  <a-icon :type="iconType" />
</template>

<script>
export default {
  props: {
    station: {
      type: Object,
      required: true
    }
  },
  computed: {
    iconType() {
      return (
        this.$store.getters['stations/getStationById'](this.station.id).icon ??
        'deployment-unit'
      )
    }
  }
}
</script>
