<template>
  <div>
    <template v-if="task.available_at">
      <a-icon type="coffee" />
      <formatted-time :value="task.available_at" format="M/DD" />
    </template>
    <template v-else>
      <a-icon type="clock-circle" />
      Unavailable
    </template>
    <a-divider type="vertical" />
    <template v-if="task.started_at">
      <a-icon type="play-circle" />
      <formatted-time :value="task.started_at" format="M/DD" />
      <a-divider type="vertical" />
    </template>
    <a-button
      v-else-if="canStart"
      icon="play-circle"
      @click="updateTask('start')"
      size="small"
    >
      Start
    </a-button>
    <template v-if="task.completed_at">
      <a-icon type="check" />
      <formatted-time :value="task.completed_at" format="M/DD" />
      <a-divider type="vertical" />
    </template>
    <a-button
      v-else-if="canComplete"
      @click="updateTask('complete')"
      icon="check"
      size="small"
    >
      Complete
    </a-button>
  </div>
</template>

<script>
import FormattedTime from '~/components/FormattedTime.vue'

export default {
  components: {
    FormattedTime
  },
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  computed: {
    canStart() {
      return !this.isAssignedToSomeoneElse && this.task.available_at
    },
    canComplete() {
      return !this.isAssignedToSomeoneElse && this.task.started_at
    },
    isAssignedToSomeoneElse() {
      if (!this.task.user_id) {
        return false
      }
      return this.task.user_id !== this.$auth.user.id
    }
  },
  methods: {
    async updateTask(action) {
      try {
        const { data } = await this.$axios.post(
          `/api/v1/tasks/${this.task.id}/${action}`
        )
        this.$notification.success({
          message: this.task.station.name + ' marked ' + action,
          description: 'Keep it up!'
        })
        this.$emit('updated', data.data)
      } catch (err) {
        this.$notification.error({
          message: 'Something went wrong',
          description: err.response
            ? 'Received a ' + err.response.status + ' response'
            : err.request
            ? 'A request was made but no response was received.'
            : 'Unable to make request.'
        })
      }
    }
  }
}
</script>
