<template>
  <a-tooltip class="FormattedTime" :placement="placement">
    <template slot="title">
      <span v-text="tooltip" />
    </template>
    <span v-text="display" />
  </a-tooltip>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    value: {
      type: String,
      required: true
    },
    format: {
      type: String,
      default: 'M/D/YY'
    },
    relative: {
      type: Boolean,
      default: false
    },
    maxRelative: {
      type: Number,
      default: 0
    },
    placement: {
      type: String,
      default: 'top'
    }
  },
  computed: {
    moment() {
      return moment(this.value)
    },
    useRelative() {
      return (
        this.relative ||
        (this.maxRelative !== 0 &&
          this.moment
            .clone()
            .add(this.maxRelative, 'seconds')
            .isAfter(moment()))
      )
    },
    display() {
      if (this.useRelative) {
        return this.moment.fromNow()
      }
      return this.moment.format(this.format)
    },
    tooltip() {
      return this.moment.format('LLL')
    }
  }
}
</script>
