<template>
  <a-tag :color="color">
    <a-icon :title="status.title" :type="icon" />
    <span v-text="labelText" />
  </a-tag>
</template>

<script>
const colors = {
  unavailable: null,
  available: 'pink',
  started: 'green',
  completed: 'blue'
}

const icons = {
  unavailable: 'clock-circle',
  available: 'coffee',
  started: 'play-circle',
  completed: 'check'
}

export default {
  props: {
    status: {
      type: Object,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    labelText() {
      return this.label ?? this.status.title
    },
    color() {
      return colors[this.status.value]
    },
    icon() {
      return icons[this.status.value]
    }
  }
}
</script>
