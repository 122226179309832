<template>
  <a-table
    :columns="displayedColumns"
    :dataSource="tasks"
    :pagination="false"
    :loading="loading"
    row-key="id"
  >
    <span slot="id" slot-scope="id, record">
      <nuxt-link
        :to="{
          name: 'orders-id',
          params: { id: record.job.order_id },
          hash: '#' + record.job.type.key
        }"
      >
        #<span v-text="record.job.order_id" />-<span
          v-text="record.job.type.key.toString().toUpperCase()"
        />
      </nuxt-link>
      <order-alert-icons :order="record.job.order" />
    </span>
    <span slot="customer" slot-scope="job">
      <nuxt-link :to="{ name: 'users-id', params: { id: job.order.user.id } }">
        <user-avatar :user="job.order.user" size="small" />
        <span v-text="job.order.user.name" />
      </nuxt-link>
      <user-tags :tags="job.order.user.tags" />
    </span>
    <div slot="station" slot-scope="station">
      <station-icon :station="station" />
      <span v-text="station.name" />
    </div>
    <div slot="bundles" slot-scope="job">
      <bundle-quantities :bundles="job.bundles" />
    </div>
    <div slot="user" slot-scope="user, record">
      <template v-if="user">
        <user-avatar :user="user" size="small" style="margin-right: 0.25rem;" />
        <span v-text="user.name" />
      </template>
      <a-button-group v-else>
        <claim-task-button :task="record" @assigned="taskAssigned" size="small">
          Claim
        </claim-task-button>
        <assign-task-dropdown :task="record" @assigned="taskAssigned">
          <template v-slot:default>
            <a-button icon="down" size="small" />
          </template>
        </assign-task-dropdown>
      </a-button-group>
    </div>
    <div slot="status" slot-scope="status, record">
      <task-status-timeline
        v-if="bulkEdit && status.value !== 'unavailable'"
        @updated="taskUpdated"
        :task="record"
      />
      <task-status-tag v-else :status="status" />
    </div>
    <div slot="updated_at" slot-scope="updated_at">
      <formatted-time :value="updated_at" :max-relative="60 * 60 * 12" />
    </div>
    <task-status-tag
      slot="station-status"
      slot-scope="status, record"
      :label="record.station.name"
      :status="status"
    />
  </a-table>
</template>

<script>
import UserTags from '~/components/UserTags.vue'
import UserAvatar from '~/components/UserAvatar.vue'
import StationIcon from '~/components/StationIcon.vue'
import FormattedTime from '~/components/FormattedTime.vue'
import TaskStatusTag from '~/components/TaskStatusTag.vue'
import ClaimTaskButton from '~/components/ClaimTaskButton.vue'
import OrderAlertIcons from '~/components/OrderAlertIcons.vue'
import BundleQuantities from '~/components/BundleQuantities.vue'
import TaskStatusTimeline from '~/components/TaskStatusTimeline.vue'
import AssignTaskDropdown from '~/components/AssignTaskDropdown.vue'

const columns = [
  {
    title: 'Job #',
    key: 'id',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' }
  },
  {
    title: 'Customer',
    key: 'customer',
    dataIndex: 'job',
    scopedSlots: { customRender: 'customer' }
  },
  {
    title: 'Station',
    key: 'station',
    dataIndex: 'station',
    scopedSlots: { customRender: 'station' }
  },
  {
    title: 'Bundles',
    key: 'bundles',
    dataIndex: 'job',
    scopedSlots: { customRender: 'bundles' }
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    title: 'Station Status',
    key: 'station-status',
    dataIndex: 'status',
    scopedSlots: { customRender: 'station-status' }
  },
  {
    title: 'Assignee',
    key: 'user',
    dataIndex: 'user',
    scopedSlots: { customRender: 'user' }
  },
  {
    title: 'Updated',
    key: 'updated_at',
    dataIndex: 'updated_at',
    scopedSlots: { customRender: 'updated_at' }
  }
]

export default {
  components: {
    UserTags,
    UserAvatar,
    StationIcon,
    FormattedTime,
    TaskStatusTag,
    ClaimTaskButton,
    OrderAlertIcons,
    BundleQuantities,
    TaskStatusTimeline,
    AssignTaskDropdown
  },
  props: {
    tasks: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    hiddenColumns: {
      type: Array,
      default() {
        return []
      }
    },
    appendedColumns: {
      type: Array,
      default() {
        return []
      }
    },
    bulkEdit: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    displayedColumns() {
      const defaultHidden = ['station-status']
      return columns.filter((column) => {
        return (
          !this.hiddenColumns.concat(defaultHidden).includes(column.key) ||
          this.appendedColumns.includes(column.key)
        )
      })
    }
  },
  methods: {
    taskAssigned(task) {
      this.$emit('taskChanged', task)
    },
    taskUpdated(task) {
      this.$emit('taskChanged', task)
    }
  }
}
</script>
